'use client'

import classnames from 'classnames'
import Link from '@/components/Link/Link'

import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import JoinWaitlist from '@/components/JoinWaitlist'
import RestaurantOpenClosedBlurb from '@/components/RestaurantOpenClosedBlurb/RestaurantOpenClosedBlurb'
import OrderDropdown from '@/components/OrderDropdown/OrderDropdown'

import { DOC_TYPES } from '@/data'

import useBreakpoint from '@/hooks/use-breakpoint'
import useCurrentPage from '@/hooks/use-current-page'
import useI18n from '@/hooks/use-i18n'

import { LOCATION_HERO_INPUT_ID } from '@/sections/LocationHero/LocationHero'

import {
  getIfRestaurantIsOpen,
  getMergedHoursWithHolidayHours,
  getPrivateDiningId,
  getRestaurantAddressString,
} from '@/utils'

import styles from './LocationsListItem.module.scss'

type LocationsListItem = SanityPage & {
  className?: string
  showPrivateDiningOnly: boolean
  index: number
  totalItems: number
  businessId?: string
  waitlistTime?: { [key: string]: string }
  waitlistStatus?: string
}

const LocationsListItem = ({
  className,
  index,
  locationData,
  showPrivateDiningOnly,
  slug,
  title,
  totalItems,
  businessId,
  waitlistTime,
  waitlistStatus,
}: LocationsListItem) => {
  const hoursFormatted = typeof locationData?.hours === 'string' ? JSON.parse(locationData?.hours) : locationData?.hours
  const { i18n } = useI18n()
  const { currentLanguage } = useCurrentPage()
  const { isMobile } = useBreakpoint()
  const addressString = getRestaurantAddressString({
    streetAddress: locationData?.streetAddress,
    city: locationData?.city,
    state: i18n(locationData?.state as string),
    postalCode: locationData?.postalCode,
  })

  const hoursWithHolidayHours = getMergedHoursWithHolidayHours(
    hoursFormatted as hoursByDay,
    locationData?.holidayHours,
  )
  const isRestaurantOpen = getIfRestaurantIsOpen(locationData?.timeZone, hoursWithHolidayHours)
  const locationLink = {
    label: '',
    linkType: 'internal',
    link: {
      _type: DOC_TYPES.LOCATION,
      slug: slug.current,
    },
  }

  const areaTitle = (
    <span className={styles.areaTitle}>
      <span className={styles.areaTitle__at}>&nbsp;{i18n('at')}</span>&nbsp;{locationData?.areaTitle}
    </span>
  )

  if (!locationData || !title || !slug?.current) return null

  return (
    <AnimateContainer
      element="li"
      animationType="fadeInUp"
      className={classnames(styles.LocationsListItem, className, { [styles.isComingSoon]: locationData?.isComingSoon })}
      style={{ zIndex: totalItems - index }}
      setImmediate={isMobile === true}
    >
      <Link
        className={styles.textSide}
        link={locationLink as SanityLink}
      >
        <div className={styles.titlesContainer}>
          <h1 className={styles.title}>
            {title} {locationData?.areaTitle && <span className={styles.areaTitleContainerMobile}>{areaTitle}</span>}
            <span className={styles.hoverRightIcon}>
              <Icon
                name="caretRight"
                className={styles.hoverRightIcon__icon}
              />
            </span>
          </h1>
          {locationData?.areaTitle && <p className={styles.areaTitleContainerDesktop}>{areaTitle}</p>}
          <div className={styles.openCloseBlurbContainerMobile}>
            <RestaurantOpenClosedBlurb
              className={styles.openClosedBlurb}
              hours={hoursFormatted}
              timeZone={locationData?.timeZone}
              holidayHours={locationData.holidayHours}
              isComingSoon={locationData?.isComingSoon}
            />
          </div>
        </div>
        <div className={styles.phoneNumberAddressContainer}>
          {locationData?.phoneNumber && (
            <p className={styles.phoneNumber}>{i18n('phoneNumberShort', { phoneNumber: locationData?.phoneNumber })}</p>
          )}
          <p className={styles.address}>
            {addressString
              ? i18n('addressShort', {
                  address: addressString,
                })
              : i18n('newLocationOpeningSoon')}
          </p>
        </div>
      </Link>
      <div className={styles.blurbColumn}>
        <div className={styles.openCloseBlurbContainerDesktop}>
          <RestaurantOpenClosedBlurb
            className={styles.openClosedBlurb}
            hours={hoursFormatted}
            timeZone={locationData?.timeZone}
            holidayHours={locationData.holidayHours}
            isComingSoon={locationData?.isComingSoon}
          />
        </div>
      </div>
      <div className={styles.ctaSide}>
        {locationData?.isComingSoon ? (
          <Button
            className={styles.comingSoonButton}
            label={i18n('notifyMeWhenOpening')}
            link={locationLink as SanityLink}
            secondary
            icon="caretRight"
            onClick={() => {
              setTimeout(() => {
                const input = document.getElementById(LOCATION_HERO_INPUT_ID)
                if (input) input.focus()
              }, 150)
            }}
          />
        ) : (
          <>
            <div className={styles.buttons}>
              <div className={styles.topButtons}>
                {locationData?.reservationLinks?.length && !showPrivateDiningOnly && (
                  <OrderDropdown
                    className={styles.reserveButton}
                    serviceLinks={locationData?.reservationLinks}
                    dropdownPosition="bottomRight"
                    isReserve
                    primary
                    slug={slug?.current}
                  />
                )}
                {locationData?.serviceLinks?.length && (
                  <OrderDropdown
                    className={styles.orderButton}
                    serviceLinks={locationData?.serviceLinks}
                    dropdownPosition="bottomRight"
                  />
                )}
              </div>
              <div className={styles.bottomButtons}>
                {showPrivateDiningOnly && (
                  <Button
                    primary
                    link={
                      {
                        label: i18n('details'),
                        linkType: 'internal',
                        link: {
                          _type: DOC_TYPES.LOCATION,
                          slug: slug.current,
                        },
                        hash: getPrivateDiningId(currentLanguage as string, slug.current),
                      } as SanityLink
                    }
                    icon="caretRight"
                  />
                )}
                {isRestaurantOpen && waitlistTime && (
                  <JoinWaitlist
                    waitlistTime={
                      waitlistTime['1-2'] || waitlistTime['3-4'] || waitlistTime['5-6'] || waitlistTime['7+']
                    }
                    secondary
                    className={styles.joinWaitlistButton}
                    businessId={businessId}
                    waitlistStatus={waitlistStatus}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </AnimateContainer>
  )
}

LocationsListItem.displayName = 'LocationsListItem'

export default LocationsListItem
