import { type ParsedResponse } from '@/types/yelp'

/**
 * Extracts the Yelp reservation link from the page data.
 *
 * @param {SanityPage} pageData
 * @returns { { url: string } | undefined }
 */
export const getDiningRoomLink = (pageData: SanityPage) => {
  return pageData?.locationData?.reservationLinks?.find((link: { url: string }) => link.url.includes('yelp.com'))
}

/**
 * Extracts the business ID from a Yelp URL.
 * Supports both reservation and business page URL formats.
 *
 * @param {string} url - The Yelp URL to parse
 * @returns {string | null} The extracted business ID or null if not found
 */
export const extractYelpBusinessId = (url: string): string | null => {
  const yelpUrlPattern = /yelp\.com\/(?:reservations|biz)\/([^/?]+)/
  const match = url.match(yelpUrlPattern)
  return match ? match[1] : null
}

export const getLocationYelpData = (diningRoomLink?: string, yelpData?: ParsedResponse[]): ParsedResponse | null => {
  if (!diningRoomLink) return null

  const yelpId = extractYelpBusinessId(diningRoomLink || '')

  return yelpData?.find(item => item.businessId === yelpId) || null
}
