'use client'

import classnames from 'classnames'
import styles from './GuestServicesHero.module.scss'
import Button from '@/components/Button/Button'
import useI18n from '@/hooks/use-i18n'
import LocationHeroHours from '@/sections/LocationHero/LocationHeroHours/LocationHeroHours'
import SanityImage from '@/components/SanityImage/SanityImage'

const GuestServicesHero = ({
  className,
  title,
  image,
  cta,
  secondaryCta,
  phoneNumber,
  email,
  hours,
  holidayHours,
  timeZone,
}: SanityGuestServicesHero) => {
  const { i18n } = useI18n()
  const hoursFormatted: hoursByDay = typeof hours === 'string' ? JSON.parse(hours) : hours
  const holidayHoursFormatted: SanityLocationHolidayHours[] = holidayHours || []

  if (!title || !image?.asset?.url || !timeZone) return null

  return (
    <div className={classnames(styles.GuestServicesHero, className)}>
      <div className={styles.inner}>
        <div className={styles.textSide}>
          <h1 className={styles.title}>{title}</h1>
          {(cta || secondaryCta) && (
            <div className={styles.buttons}>
              {cta && (
                <Button
                  link={cta}
                  primary
                  className={styles.buttonPrimary}
                />
              )}
              {secondaryCta && (
                <Button
                  link={secondaryCta}
                  secondary
                  className={styles.buttonSecondary}
                />
              )}
            </div>
          )}
          <div className={styles.phoneNumberEmailContainer}>
            {phoneNumber && (
              <p className={styles.phoneNumber}>
                <a href={`tel:${phoneNumber}`}>{i18n('phoneNumberShort', { phoneNumber })}</a>
              </p>
            )}
            {email && (
              <p className={styles.email}>
                <a href={`mailto:${email}`}>{i18n('emailShort', { email })}</a>
              </p>
            )}
          </div>
          {hoursFormatted && timeZone && (
            <LocationHeroHours
              className={styles.locationHours}
              hours={hoursFormatted}
              timeZone={timeZone}
              holidayHours={holidayHoursFormatted}
            />
          )}
        </div>
        <div className={styles.imageSide}>
          <div className={styles.imageContainer}>
            <SanityImage
              image={image}
              className={styles.image}
              priority
              breakpoints={{
                tablet: {
                  width: 1200,
                  image,
                },
                xs: {
                  width: 800,
                  image,
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

GuestServicesHero.displayName = 'GuestServicesHero'

export default GuestServicesHero
